import React from 'react'
import Item from './item'
import ActiveItem from './activeitem'
import LoginCard from './logincard'
  
class List extends React.Component {
  constructor() {
    super()

    this.state = {
      cocktails : [
      {id:1,type: 'recipe',name: 'Negroni',description: 'The king of cocktails',ingredients: '1oz Gin, 1oz Sweet Vermouth, 1oz Campari',instructions: 'mix and drink',active: false,color: "#CC333F"},
      {id:2,type: 'recipe',name: 'Manhattan',description: 'The Grand daddy of cocktails',ingredients: 'Rye Whiskey, Sweet Vermouth, anigustora bitters',instructions: 'mix and drink',active: false,color: '#542337'},
      {id:3,type: 'recipe',name: 'sidecar',description: 'Smooth and classy',ingredients: 'cognac, lemon juice, simple syrup and dry curaco',instructions: 'mix and drink',active: false,color: '#E7D84D'},
      {id:4,type: 'recipe',name: 'Last Word',description: 'Sophisticated and lucious',ingredients: 'gin, green chartruse, maraschino liquer',instructions: 'mix and drink',active: false,color: '#A7C5BC'},
      {id:5,type: 'recipe',name: 'Cucumber delight',description: 'Light and refreshing',ingredients: 'vodka, ginger liquer, lemon, simple syrup',instructions: 'mix and drink',active: false,color: '#BDA157'},
      {id:6,type: 'recipe',name: 'Boulavardier',description: 'Classy cousin of the negroni',ingredients: 'vodka, ginger liquer, lemon, simple syrup',instructions: 'mix and drink',active: false,color: '#81964F'},
      {id:7,type: 'recipe',name: 'Margarita',description: 'Everyday is cinco de mayo!',ingredients: 'vodka, ginger liquer, lemon, simple syrup',instructions: 'mix and drink',active: false,color: '#CC333F'}
    ]
    }
  }
  handleLoginChange(event) {
    event.preventDefault
    let drinks = [...this.state.cocktails]
    drinks.map(data => {
      data.active = false
      this.setState({drinks})
    })
    let checkRecipeCards = document.getElementsByClassName('active')
      let activeCards = [...checkRecipeCards]
      activeCards.map( data => {
        data.classList.remove('active')
      })
    event.currentTarget.className += ' active'
  }
  handleItemChange(item, event) {
    event.preventDefault
    let checkLoginCard = document.getElementById('account_card')
    if (checkLoginCard.classList.contains('active')) {
      checkLoginCard.classList.remove('active')
    }
    let drinks = [...this.state.cocktails]
    drinks.map( data => {
      data.active = false
    })
    item.active = !item.active
    drinks[item.id -1] = item
    this.setState({drinks})
  }
  render() {
    const cards = this.state.cocktails.map( (item) => {
      let card = null
      let boundHandleItemChange = this.handleItemChange.bind(this,item)

      return (item.active ? <ActiveItem handleItemChange={boundHandleItemChange} key={item.id} name={item.name} description={item.description} ingredients={item.ingredients} instructions={item.instructions} color={item.color}/>
      : <Item handleItemChange={boundHandleItemChange} key={item.id} name={item.name} description={item.description} ingredients={item.ingredients} instructions={item.instructions} color={item.color}/>)     
    })
    let boundHandleLoginChange = this.handleLoginChange.bind(this)
    let name = this.props.name
  return (
    <div className="cards">
      <LoginCard handleLoginChange={boundHandleLoginChange} name={name} sign_out_path={this.props.sign_out_path}/>
      {cards}
   </div>
  )
  }
}

export default List
