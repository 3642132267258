import React from 'react'

class Item extends React.Component {
  render() {
    const color = {
      backgroundColor: this.props.color
      }
  return (
    <div onClick={this.props.handleItemChange} className="card" style={color} >
      <div className="card__content">
        <h3>{this.props.name}</h3>
        <p className='description'>{this.props.description}</p>
      </div>
      <footer className="card__footer">
      </footer>
    </div>
  ) 
  }
  }
export default Item
