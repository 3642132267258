import React from 'react'

class Instructions extends React.Component {
  render() {
    return(
      <p>{this.props.instructions}</p>
    )
  }
}

export default Instructions 
