import React from 'react'
import ShareIcon from '../../assets/images/share.svg'

class ShareButton extends React.Component {

  addWebShare()  {
      const recipeData = {
          title: this.props.recipe.name,
          text: 'Ingredients:'.concat(' ',this.props.recipe.ingredients.concat(' ','Instructions:',' ',this.props.recipe.instructions)),
          url: 'https://seriousbitters.com'
      }
  	if (navigator.share) {
    navigator.share(recipeData).then(() => {
    })
    .catch(console.error);
  } else {
    console.log('Sharing Unsupported')
    alert(recipeData.title+recipeData.url)
  }
  } 
  render() {
    return (
      <p> <button className="share_button" type='button' onClick={this.addWebShare.bind(this)}><img src={ShareIcon} /></button></p>
    )
  }
}

export default ShareButton
