import React from 'react'
import ShareButton from './sharebutton'
import Ingredients from './ingredients'
import Instructions from './instructions'

class ActiveItem extends React.Component {
  render() {
  return (
    <div onClick={this.props.handleItemChange} className="card active" style={{'backgroundColor': this.props.color}}>
      <div className="card__content">
        <h4>{this.props.name}</h4>
        <Ingredients ingredients={this.props.ingredients} />
        <Instructions instructions={this.props.instructions} />
      </div>
      <footer className="card__footer">
        <span><ShareButton recipe={this.props} /> </span>
      </footer>
    </div>
  ) 
  }
  }
export default ActiveItem
