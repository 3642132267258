import React from 'react'

class LoginCard extends React.Component {
  

  render() {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
    return (
      <div onClick={this.props.handleLoginChange} className='card' id='account_card'>
        <p> Hi {this.props.name}! We're happy you are here</p>
		<form method="post" action="/sign_out"><input type="hidden" name="_method" value="delete" /><input type="submit" value="Sign out" /><input type="hidden" name="authenticity_token" value={csrf} /></form>
      </div>
    )
  }

}
export default LoginCard 
